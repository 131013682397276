.quo {
  padding: 4% 4% 8% 0;
  position: relative;
}
.quo:before,
.quo:after {
  position: absolute;
  font-size: 50px;
  color: #000000;
}
.quo:before {
  content: "\201c";
  top: 0;
  left: -10px;
  right: auto;
}
.quo:after {
  content: "\201d";
  bottom: 0;
  /* right: 10px; */
}
