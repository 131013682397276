#nav a.active {
  font-weight: 800;
  /* text-decoration: underline; */
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
#basic-nav-dropdown {
  color: #000;
}
#nav a {
  color: #000;
}

#pro.dropdown-toggle::after {
  display: none !important;
}
